

import * as logger from "@/tools/logger.js";
import * as statusMapper from "@/service/error_request_mapper.js";


const _FILE_PATH = "service/sfr/global_rubrics_service.js";
const _M_GET_ALL = "getAllGlobalRubrics";
const _M_GET_BY_ID = "getGlobalRubricsById";


/** Service d'accès à l'API des rubriques globales */
export default class GlobalRubricsService {

  constructor(apis) {
		this.apis = apis;
		this.api = this.apis.getGlobalRubricsApi();
	}
	

/////////////////////////////// GET /////////////////////////////////////////
	/**
	 * Récupère la liste des motifs maladie et les tri par ordre alphabétique
	 */
	async getAllGlobalRubrics() {
		logger.debugFull(_FILE_PATH,
		_M_GET_ALL,
		"Récupération de toutes les rubriques globales"
		);

		return this.api.getAll()
			.then((data) => {
				data.sort(function(a, b) {
					return a.name.localeCompare(b.name);
				});
				return data;
			})
			.catch((error) => {
				let converter = new statusMapper.StatusConverter();
				converter.convert(error);
			});
	}


	/**
	 * Récupère une rubrique globale par son id 
	 */
	async getGlobalRubricsById(id) {
		logger.debugFull(_FILE_PATH,
			_M_GET_BY_ID,
			"Récupération d'une rubrique globale avec l'id : " + id
		);

		return this.api.getById(id)
			.catch((error) => {
				let converter = new statusMapper.StatusConverter();
				converter.convert(error);
			});
	}


/////////////////////////////// CREATE /////////////////////////////////////////

async create(item) {
	return this.api.create(item)
	.catch((error) => {
		let converter = new statusMapper.StatusConverter();
		converter.add404(item.id);
		converter.add409Integrity(item.name);
		converter.convert(error);
	});
}

/////////////////////////////// UPDATE /////////////////////////////////////////

async update(item) {
	return this.api.update(item)
	.catch((error) => {
		let converter = new statusMapper.StatusConverter();
		converter.add404(item.id);
		converter.convert(error);
	});
}

/////////////////////////////// DELETE /////////////////////////////////////////

async delete(itemId) {
	return this.api.delete(itemId)
	.catch((error) => {
		let converter = new statusMapper.StatusConverter();
		converter.convert(error);
	});
}

}
