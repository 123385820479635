<template>
  <v-container fluid>
    <TableViewComponent
      title="Table rubriques globales"
      @addItemEvent="onAddItem()"
      addItemLabel="ajouter une rubrique globale"
      itemLabel="rubrique globale"
      itemsLabel="rubriques globales"
      :items="globalRubrics"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette rubrique globale ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>



<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import GlobalRubricsService from "@/service/sfr/global_rubrics_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableGlobalRubrics",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**le service d'accès au APIs. */
      globalRubricsService: null,

      /**la liste des rubriques */
      globalRubrics: [],
    };
  },
  methods: {
    onAddItem() {
      this.$router.push(routes.globalRubrics.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.globalRubrics = [];
        
        // Récupération de toutes les rubriques globales
        let globalRubrics = await this.globalRubricsService.getAllGlobalRubrics();

        globalRubrics.forEach((r) => {
          let rubric = JSON.parse(JSON.stringify(r));

          //action de consultation
          rubric.view = () => {
            this.$router.push(routes.globalRubrics.view.root + "/" + rubric.id);
          };

          // action de modification
          rubric.edit = () => {
            this.$router.push(routes.globalRubrics.edit.root + "/" + rubric.id);
          };

          //action de suppression
          rubric.delete = async () => {
            try {
              await this.globalRubricsService.delete(rubric.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de la rubrique globale : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.globalRubrics.push(rubric);
        });

        // console.log(JSON.stringify(this.functions, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "name",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditServiceSFR];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditServiceSFR, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadServiceSFR];
    },
  },
  mounted() {
    // Instanciation du service
    this.globalRubricsService = new GlobalRubricsService(this.$api);

    this.load();
  },

};
</script>
